import React, { useState, useEffect } from 'react'
import http from './http-common'
import MapThumbnail from './map-thumbnail'

const DashboardFavorites = (props) => {
    const [favorites, setFavorites] = useState([])
    const [pagination, setPagination] = useState({ next: null, previous: null });
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    const fetchFavorites = async (url) => {
        try {
            const endpoint = url || `/${props.activity_type}/user-favorites/`;
            const res = await http.get(endpoint)
            setFavorites(res.data.results)
            setPagination({ next: res.data.next, previous: res.data.previous })
            setIsLoading(false)
        } catch (error) {
            setIsError(true)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchFavorites()
    }, [])

    return (
        <>
            <div className={"border bg-slate-300 rounded-2xl p-4 mb-4"}>
            {isLoading ? <div className="animate-pulse h-4 bg-gray-300 rounded-full dark:bg-gray-700 w-full"></div> :
                <div className="capitalize">
                    <div className={"font-bold mb-2 text-xl"}>
                    {props.title}
                    </div>
                    <div className={"flex flex-wrap"}>
                    {favorites.length > 0 && favorites.map((favorite) => (
                        <div key={favorite.id} className="w-full md:w-96 pb-2 pr-2">
                            <button className={"w-full"} onClick={() => { window.location = `/${props.activity_type}/${favorite?.activity.slug}/` }}>
                                <div className="card bg-base-100 shadow-xl">
                                    <div className="card-body">
                                        <MapThumbnail url={favorite?.activity.map_thumbnail} />
                                        <h2 className="card-title">{favorite?.activity.name}</h2>
                                        <p className="text-left">{favorite?.activity.location}</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                    </div>
                    {favorites.length === 0 && <div className="card w-96">No favorites yet</div>}
                    <div className="pagination flex justify-between mt-2">
                        {pagination.previous && <button className={"btn btn-sm btn-outline bg-white"} onClick={() => fetchFavorites(pagination.previous)}>Previous</button>}
                        {pagination.next && <button className={'btn btn-sm btn-outline bg-white'} onClick={() => fetchFavorites(pagination.next)}>Next</button>}
                    </div>
                </div>
            }
            </div>
        </>
    )
}

export default DashboardFavorites;
