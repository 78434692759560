import { Link } from 'react-router-dom';
import React, {useState} from "react";
import http from "../../components/http-common";
import CloseToLastPage from "../../components/close-to-last-page";
import {CURRENT_PAGE_URL} from "./redirect-to-last-page";
import GoogleLoginComponent from "../../components/oauth/google-login";


export function RegisterPage() {

    const [showPassword, setShowPassword] = useState(false)

    const [displayNameError, setDisplayNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [displayName, setDisplayName] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmail = event => {
        setEmail(event.target.value)
    }

    const handleDisplayName = event => {
        setDisplayName(event.target.value)
    }

    const handlePassword = event => {
        setPassword(event.target.value)
    }

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const fetchlogin = async (e) => {
        e.preventDefault()
        const res = await http.post('/auth/users/', {username: displayName, email: email, password: password});
        console.log(res)
        if (res?.status === 201) {
            console.log(res)
            setDisplayNameError("")
            setEmailError("")
            setPasswordError("")

            // get a token with the new user at /token
            const res2 = await http.post('/token/', {email: email, password: password});
            console.log(res2)
            if (res2?.status === 200) {
                let data = res2.data

                localStorage.setItem('refresh_token', data.refresh)
                localStorage.setItem('access_token', data.access)
            }

            let redirect_url = sessionStorage.getItem(CURRENT_PAGE_URL)
            if (redirect_url) {
                window.location.assign(redirect_url)
            }
            else {
                window.location.assign('/')
            }

        } else {
            setDisplayNameError("")
            setEmailError("")
            setPasswordError("")
            let dError = res?.response?.data?.username
            if (dError) {
                dError = dError[0].replace("username", "display name")
                setDisplayNameError(dError)
            }

            let eError = res?.response?.data?.email
            if (eError) {
                setEmailError(eError)
            }

            let pError = res?.response?.data?.password
            if (pError) {
                pError = pError[0]
                setPasswordError(pError)
            }

        }
    };

    const ShowPasswordIcon = () => {

        if (showPassword === true) {
            return (
                <svg onClick={handleShowPassword} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLineJoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"/>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                </svg>
            )

        }
        else {
            return(
                <svg onClick={handleShowPassword} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"/>
                </svg>
            )
        }
    }

    return (
        <div className="bg-cover bg-center bg-hero-pattern flex min-h-full flex-col justify-center w-full h-screen">
            <div className={"flex flex-col justify-center items-center  backdrop-blur-lg h-screen w-full"}>


                <div className="mt-16 w-full sm:w-full sm:max-w-xl">
                    <div className="relative filter-none bg-white pb-8 px-4 shadow sm:rounded-md md:px-10">
                        <CloseToLastPage/>
                        <div className="flex justify-center items-center w-full backdrop-blur-lg">
                        </div>
                        <h2 className="pt-4 text-center font-black text-xl tracking-wider">Create Your Account</h2>
                        {displayNameError !== "" ?
                            <div className={"mt-4 text-red-500 text-sm text-center"}>
                                <ul>
                                    <li>{displayNameError}</li>
                                </ul>
                            </div>
                            : <></>
                        }
                        <div className="flex justify-center mt-4 items-center sm:rounded-md">
                            <GoogleLoginComponent/>
                        </div>

                        <div className="flex justify-center mt-4 items-center sm:rounded-md">
                            <div className="border-b w-1/3"></div>
                            <div className="mx-2">Or</div>
                            <div className="border-b w-1/3"></div>
                        </div>

                        {emailError !== "" ?
                            <div className={"mt-4 text-red-500 text-sm text-center"}>
                                <ul>
                                    <li>{emailError}</li>
                                </ul>
                            </div>
                            : <></>
                        }
                        {passwordError !== "" ?
                            <div className={"mt-4 text-red-500 text-sm text-center"}>
                                <ul>
                                    <li>{passwordError}</li>
                                </ul>
                            </div>
                            : <></>
                        }
                        <form onSubmit={fetchlogin} className="mx-auto md:max-w-sm space-y-4">
                            <div>
                                <label htmlFor="display" className="mt-2 block text-md font-medium">
                                    Display Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="display"
                                        name="display"
                                        type="text"
                                        onChange={handleDisplayName}
                                        value={displayName}
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-md font-medium">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={handleEmail}
                                        value={email}
                                        autoComplete="email"
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-md font-medium">
                                    Password
                                </label>
                                <div className="mt-1 relative">

                                    <input
                                        id="password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        onChange={handlePassword}
                                        value={password}
                                        autoComplete="current-password"
                                        required
                                        className="block inline w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm pr-10"
                                    />
                                    <div className={"absolute inset-y-0 right-3 flex items-center leading-5"}>
                                        <ShowPasswordIcon/>
                                    </div>


                                </div>


                            </div>

                            <div className="text-right justify-between">
                                {/*<div className="flex items-center">*/}
                                {/*    <input*/}
                                {/*        id="remember-me"*/}
                                {/*        name="remember-me"*/}
                                {/*        type="checkbox"*/}
                                {/*        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"*/}
                                {/*    />*/}
                                {/*    <label htmlFor="remember-me" className="ml-2 block text-sm">*/}
                                {/*        Remember me*/}
                                {/*    </label>*/}
                                {/*</div>*/}

                            </div>

                            <div className={"flex flex-col items-center"}>
                                <button
                                    type="submit"
                                    className="mt-2 flex w-full w-1/3 items-center justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Sign Up
                                </button>
                                <div className={"pt-1 text-sm"}>Already have an account? <Link
                                    className={"underline text-blue-600 hover:text-blue-800"} to={"/login/"}>Sign
                                    In</Link></div>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>

    )
}