import NavBar from "../components/navbar";
import React from 'react';
import Footer from "../components/footer";

const TermsAndConditionsPage = (props) => {

    return (
        <div className="">
            <NavBar></NavBar>
            <div className="">
                <div class="container mx-auto mt-10 px-4 font-bold">
                        <h1 class="text-2xl font-bold mt-5 mb-2">Terms and Conditions for Adventuresum</h1>
                        <p class="text-lg font-semibold">Last Updated: January 6, 2024</p>

                        <p>Welcome to AdventureSum. By accessing our website, you agree to these Terms and Conditions. Please read them carefully.</p>

                        <h2 class="text-xl font-semibold mt-4">1. Acceptance of Terms</h2>
                        <p>By using our website, you agree to be bound by these Terms and Conditions and our Privacy Policy.</p>

                        <h2 class="text-xl font-semibold mt-4">2. No Fees for Service</h2>
                        <p>Our website does not directly charge users for any services. Access to and use of the website is free of charge.</p>

                        <h2 class="text-xl font-semibold mt-4">3. Revenue Generation</h2>
                        <ul class="list-disc ml-5">
                            <li><strong>Advertising:</strong> We display advertisements on our site, which may be delivered by third-party ad networks such as Google Ads.</li>
                            <li><strong>Affiliate Links:</strong> Our website may feature affiliate links, where we may earn a commission if you purchase through these links.</li>
                            <li><strong>Merchandise Sales:</strong> We sell apparel and other merchandise on our site. These sales are subject to additional terms and conditions which are provided at the point of sale.</li>
                        </ul>

                        <h2 class="text-xl font-semibold mt-4">4. Intellectual Property Rights</h2>
                        <p>All content on this site, including text, graphics, logos, and images, is the property of Adventuresum or its content suppliers and is protected by international copyright and intellectual property laws.</p>

                        <h2 class="text-xl font-semibold mt-4">5. User Conduct</h2>
                        <p>Users must not use our website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the site.</p>

                        <h2 class="text-xl font-semibold mt-4">6. Disclaimer of Warranties</h2>
                        <p>Our website is provided “as is,” and we do not warrant the completeness or accuracy of the information published on our website.</p>

                        <h2 class="text-xl font-semibold mt-4">7. Limitation of Liability</h2>
                        <p>In no event will Adventuresum, or its suppliers or licensors, be liable in relation to the contents of, or use of, or otherwise in connection with, this website.</p>

                        <h2 class="text-xl font-semibold mt-4">8. External Links</h2>
                        <p>Our website may include links to external websites. We have no control over the content of these websites.</p>

                        <h2 class="text-xl font-semibold mt-4">9. Changes to Terms and Conditions</h2>
                        <p>We reserve the right to modify these terms at any time. Your continued use of the site after such changes will constitute acknowledgment and agreement of the modified terms.</p>

                        <h2 class="text-xl font-semibold mt-4">10. Governing Law</h2>
                        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of California, United States of America. Any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the state and federal courts located in California for the resolution of any disputes.</p>

                        <h2 class="text-xl font-semibold mt-4">11. Contact Us</h2>
                        <p className={"pb-10"}>If you have any questions about these Terms and Conditions, please contact us at support@adventuresum.com.</p>
                    </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default TermsAndConditionsPage;