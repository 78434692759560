
export const HEADER_LINKS= [
    // {
    //     "name": "About",
    //     "link" : "/"
    // },
    {
        "name": "Explore",
        "link" : "/explore/"
    },
    {
        "name": "Store",
        "link" : "https://store.adventuresum.com"
    },

]