import React, {useEffect} from 'react';
import {Disclosure} from '@headlessui/react'
import logo_icon from "../logo-icon.png"
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import {LoggedInHamburgerPanel, LoggedInPanel} from "./logged-in-panel";
import {HEADER_LINKS} from "./header-list-items";
import {Link} from "react-router-dom";
import {useLogin} from "../../hooks/use-login";
import CommandKSearch from "../search/command-k-search";


function NavList() {
    const pathArray = window.location.pathname.split('/')
    const navListStyle = "md:text-md text-white hover:text-slate-300 inline-flex items-center px-1 pt-1 font-medium"

    let isHomePage = false
    let loginHook = useLogin()
    const [isSearchVisible, setIsSearchVisible] = React.useState(false)

    // is is the home page if [1] is an empty string
    // is is the home page if [1] is an empty string
    if (pathArray[1] === "") {
        isHomePage = true
    }


    const listItems = HEADER_LINKS.map((element) => {

        if (element.name === "Explore") {
                return (<Link key={"listItem" + element.name} to={element.link} className={navListStyle}>Explore</Link>)
        }
        else {
            return (<a key={"listItem" + element.name} href={element.link}
               className={navListStyle}>{element.name}</a>)
        }
        })

    useEffect(() => {
        const handleKeyDown = (event) => {
            // For Mac

            const isMac = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;

            if (isMac && event.metaKey && event.key === 'k') {
                setIsSearchVisible(!isSearchVisible);
                event.preventDefault(); // Prevents the default browser behavior
            }
            // For Windows
            else if (!isMac && event.ctrlKey && event.key === 'k') {
                setIsSearchVisible(!isSearchVisible);
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Disclosure as="nav" className={ isHomePage ? "" : "bg-primary"}>
            {({ open }) => (
                <>
                    <div className=" sm:h-16 md:h-20 md:justify-between w-full mx-auto">
                        <div className="flex justify-between sm:h-16 md:h-20">
                            <div className=" flex my-auto md:ml-6 w-screen md:mr-6">
                                <div className="mr-2 flex items-center md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>

                                <div className="flex items-center">
                                    {/* do not display logo on home page */}
                                    <a href="/">
                                        <img className="invisible md:visible absolute top-2 sm:left-4 sm:w-14" src={logo_icon} alt="Adventuresum" />
                                    </a>

                                </div>
                                <div className="invisible sm:visible w-screen flex-shrink-0 items-center text-center justify-center md:h-20 text-center md:flex lg:space-x-35 md:space-x-28 whitespace-pre">

                                    {listItems}
                                    { loginHook.isLoggedIn ? <LoggedInPanel></LoggedInPanel> : <><div className={navListStyle}><Link className={"hover:text-slate-300"} to={"/login"}>Login</Link> / <Link className={"hover:text-slate-300"} to={"/register"}>Signup</Link></div></> }
                                </div>
                                <div onClick={() => {setIsSearchVisible(true)}}>
                                    <svg className="absolute right-2 top-2 h-6 w-6 sm:h-8 sm:w-8 sm:top-7 sm:right-4 text-white hover:cursor-pointer"
                                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <CommandKSearch isVisible={isSearchVisible} setIsVisible={setIsSearchVisible}></CommandKSearch>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Disclosure.Panel className="visible sm:invisible">
                        <div className="pt-2 pb-3 space-y-1">
                            <Disclosure.Button>
                                <Link to="/explore" className="border-transparent text-white hover:bg-gray-50 hover:border-gray-300  block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6">
                                    Explore
                                </Link>
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="https://store.adventuresum.com"
                                className="border-transparent text-white hover:bg-gray-50 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                            >
                                Store
                            </Disclosure.Button>
                            <Disclosure.Button>
                                { loginHook.isLoggedIn ? <LoggedInHamburgerPanel></LoggedInHamburgerPanel> :
                                    <Link to={"/login"} className="border-transparent text-white hover:bg-gray-50 hover:border-gray-300  block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6">Login</Link>
                                }
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="div">
                                { loginHook.isLoggedIn ? <></> :
                                    <Link to={"/register"} className="border-transparent text-white hover:bg-gray-50 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6">Signup</Link>
                                }
                            </Disclosure.Button>
                        </div>

                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default NavList