import React, {useEffect} from 'react'
import * as Sentry from "@sentry/react";



// react component that receives a url of an image and returns a thumbnail of the image
const MapThumbnail = ({url}) => {

    let thumbnailUrl = url



    const handleError = (e) => {
    }

    return (
        <div className="w-full w-36 h-24">
            <img src={thumbnailUrl} alt="" className="w-full rounded h-full object-cover" />
        </div>
    )
}

export default MapThumbnail