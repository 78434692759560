import Nav from "../components/nav";
import React from "react";
import Footer from "../components/footer";
import DashboardFavorites from "../components/dashboard-favorites";
import DashboardComments from "../components/dashboard-comments";


const DashboardPage = () => {

    return (
        <>
            <div className={""}>
            <Nav></Nav>
            <div className={"mt-4"}>
                <div className={"flex flex-wrap pl-8 pr-8 gap-4"}>
                    <DashboardComments title={"Trail Comments"} activity_type={"trails"}></DashboardComments>
                    <DashboardFavorites title={"Trail Favorites"} activity_type={"trails"}></DashboardFavorites>
                    <DashboardComments title={"Climbing Comments"} activity_type={"climbing"}></DashboardComments>
                    <DashboardFavorites title={"Climbing Favorites"} activity_type={"climbing"}></DashboardFavorites>
                    <DashboardComments title={"Camping Comments"} activity_type={"campsites"}></DashboardComments>
                    <DashboardFavorites title={"Camping Favorites"} activity_type={"campsites"}></DashboardFavorites>
                </div>
            </div>
            </div>
            <Footer></Footer>
        </>

    )
}

export default DashboardPage;