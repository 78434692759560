import React from 'react';
import NavList from './header/navlist';

import {CURRENT_PAGE_URL} from "../pages/auth/redirect-to-last-page";

const Navbar = () => {
    sessionStorage.setItem(CURRENT_PAGE_URL, window.location.pathname)    // set the current page url in local storage using the CURRENT_PAGE_URL key


    return (
        <NavList></NavList>
    )
}

export default Navbar