import NavBar from "../components/navbar";
import React from 'react';
import Footer from "../components/footer";

const ContactUsPage = (props) => {

    return (
        <div className="relative h-screen">
            <NavBar></NavBar>
            <div className="">
                <div>
                    <div className={"container mx-auto mt-10 px-4 text-2xl font-bold"}>How to Contact Us:</div>

                    <div class="container mx-auto px-4">
                        <h1 className="text-2xl font-bold mt-5 mb-2">Send us an email at <span className="italic">support@adventuresum.com</span> </h1>

                    </div>


                </div>
            </div>
            <div className={"absolute w-screen bottom-0"}>
                <Footer></Footer>
            </div>
            </div>
    )
}

export default ContactUsPage;